/// <reference path='../../../../../node_modules/@types/jquery/index.d.ts' />
/// <reference path="../../../Cider.Framework/Assets/TypeScript/modal-helper.ts" />
/// <reference path="../../../Cider.Framework/Assets/TypeScript/form-validation.ts" />
/// <reference path="../../../Cider.Framework/Assets/TypeScript/antiforgery.ts"/>

$(document).ready(() => {
    StockNotification.bindProductStockNotification();
});

class StockNotification {

    public static bindProductStockNotification() {
        $(".stock-notification-location")
            .not(".listening")
            .on("click", ".notify-me-button", (e: Event) => StockNotification.notifyMe($(e.currentTarget)))
            .each((_index:Number, elem:Element) => {
                var location: JQuery = $(elem);
                var ajaxUrl: string = location.data("request-url");
                var itemNumber: string = location.data("item-number");
                StockNotification.loadStockNotification(location, ajaxUrl, itemNumber);
            }).addClass("listening");

    }

    private static loadStockNotification(stockNotificationLocation: JQuery, ajaxUrl: string, itemNumber: string) {
        var data = {
            itemNumber: itemNumber
        };
        $.ajax({
            url: ajaxUrl,
            data: data,
            success: (html: string) => {
                stockNotificationLocation.html(html);
                $.validator.addMethod("emailAndPhonePopulation",
                    (value, element: HTMLInputElement) => { return this.validateEmailAndPhonePopulation(value, element); });
                FormValidation.rebuildRules($("#stock-notification-form"));
            }
        });
    }

    private static validateEmailAndPhonePopulation(_value, element: HTMLInputElement): boolean {
        var stockNotificationForm: JQuery = $(element).parents("#stock-notification-form");
        var phoneNumberField: JQuery = stockNotificationForm.find("[type=tel]");
        var emailAddressField: JQuery = stockNotificationForm.find("[type=email]");
        if (phoneNumberField.length > 0 && emailAddressField.length > 0 
            && emailAddressField.attr("data-val-required") == null 
            && phoneNumberField.attr("data-val-required") == null
            && emailAddressField.val() != null && emailAddressField.val().trim().length === 0 
            && phoneNumberField.val() != null && phoneNumberField.val().trim().length === 0) {
            // The email and/or phone number are not already required, and both fields are shown and are blank
            // One must be populated, so validation fails here
            $(".blank-fields-error").removeClass("hidden");
            return false;
        }

        $(".blank-fields-error").addClass("hidden");
        return true;
    }

    public static notifyMe(button: JQuery) {
        var modal: JQuery = $('#stock-notification');
        var modalHelper: ModalHelper = new ModalHelper(modal);
        modalHelper.show();

        modal.on("click", ".btn-primary", () => {
            // Create validation object
            var validationObject = $('#stock-notification-form').validate({ errorPlacement: () => true });

            // Add rule to perform validation that either email or phone are specified 
            var emailAddressField = modal.find("[type=email]");
            var phoneNumberField = modal.find("[type=tel]");
            if (emailAddressField.length > 0 && emailAddressField.val() !== null) {
                emailAddressField.rules('add', { "emailAndPhonePopulation": true });
            }
            if (phoneNumberField.length > 0 && phoneNumberField.val() !== null) {
                phoneNumberField.rules('add', { "emailAndPhonePopulation": true });
            }
            // Perform validation
            if (!validationObject.form()) {
                return;
            }

            var data = AntiForgeryTokenHelper.addAntiForgeryToken(modalHelper.getData());
            var url = modal.attr('data-request-url');

            $.post(url, data,
                (html: string) => {
                    modal.on('hidden.bs.modal', () => { button.parent(".stock-notification-location").html(html) });
                    modalHelper.save();
                    $('.ajax-error').addClass('hidden');
                }).fail((data) => {
                    $('.ajax-error').removeClass('hidden');
                    $('.ajax-error').html(data.responseText);
                });
        })
    }
}